// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-attacks-js": () => import("./../../../src/pages/attacks.js" /* webpackChunkName: "component---src-pages-attacks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-js": () => import("./../../../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-new-index-js": () => import("./../../../src/pages/new-index.js" /* webpackChunkName: "component---src-pages-new-index-js" */),
  "component---src-pages-what-js": () => import("./../../../src/pages/what.js" /* webpackChunkName: "component---src-pages-what-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-og-image-js": () => import("./../../../src/templates/og-image.js" /* webpackChunkName: "component---src-templates-og-image-js" */)
}

